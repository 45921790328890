.grid-container {
    background-image: url("../assets/images/manifesto.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-auto-rows: minmax(120px, auto);
    gap: 2px;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 2px;
    overflow: hidden;
}

.absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #75ff75;
    z-index: 10;

    font-size: 100px;
}

.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: background-color 0.3s ease;
    overflow: hidden;
}

.grid-item:hover {
    opacity: 1;
}

.grid-item .content {

    background-size: cover;
    background-position: center;

    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;


}

.grid-item:hover .content {

    opacity: 1;
}

@media (max-width: 600px) {

    .grid-container {
        background-image: url("../assets/images/manifesto-mobile.png");

        grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
        grid-auto-rows: minmax(50px, auto);
    }

    .grid-item .content {
        font-size: 50px;
    }
}